import momemt from 'moment';
import 'slick-carousel'

jQuery( document ).ready(function($) {

  // mobile menu
  $('.menu-trigger').click(function(){
    $('.menu').slideToggle()
  })

  // newsletter
  $("#rtk").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('#rtk, .newsletter-alert.error').hide()
        $('.newsletter-alert.success').show()
      },
      error: function (err) {
        $('.newsletter-alert.error').show()
      }
    });
  });

  // slider
  $('.slider-music').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: false,
    infinite: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });

  // events
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Rich%20The%20Kid/events?app_id=45PRESS_RICH_THE_KID',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert( 'Error fetching events!' );
    },
    success: data => {
    const events = $( '#events' );
    let html = '';
    if ( data.length ) {
      for ( let event of data ) {
        let location = event.venue.city + ', ' + event.venue.region;
        if ( location === ', ' ) {location = '';}
        if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
        html += '<div class="event">';
        html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
        html += '<div class="event-location">' + location + '</div>';
        html += '<div class="event-venue">' + event.venue.name + '</div>';
        html += '<div class="event-links">';
        for ( let offer of event.offers ) {
          html += '<a href="' + offer.url + '" target="_blank" class="btn btn-outline-white">' + offer.type + '</a>';
        }
        html += '</div>';
        html += '</div>';
      }
      events.html( html );
      } else {
        events.html( 'No upcoming events.' );
      }
    }
  });

  $('.btn-events').click(function(e){
    e.preventDefault()
    $('#events').toggleClass('all')
    if($(this).html() === 'more'){
      $(this).html('less')
    } else{
      $(this).html('more')
    }
  })



});